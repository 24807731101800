export const es = {
  nav: {
    firm: 'La Firma',
    services: 'Servicios',
    people: 'Equipo',
    contact: 'Contacto',
    workWithUs: 'Contáctenos',
    testimonials: 'Reconocimientos'
  },
  hero: {
    title: {
      line1: 'Navegamos en la complejidad',
      line2: 'del derecho para entregar',
      line3: 'soluciones',
      line4: 'prácticas'
    },
    cta: {
      contact: 'Contáctenos'
    }
  },
  firm: {
    title: {
      line1: 'Nos asociamos con nuestros clientes para',
      line2: 'diseñar soluciones',
      line3: 'a sus',
      line4: 'desafíos más importantes'
    },
    description1: 'No solo manejamos casos legales. Diseñamos soluciones legales efectivas. Comenzamos por entender las formas más valiosas de resolver los desafíos de nuestros clientes, luego trabajamos con ellos para dar vida a esas soluciones de una manera que pone sus objetivos comerciales en su punto máximo.',
    description2: 'Para construir una solución legal, miramos más allá de las necesidades del momento actual. Planificamos para hacerla inteligente y adaptable a medida que crece. Aprovechando siete generaciones de tradición legal, nos aseguramos de que su negocio obtenga las herramientas exactas necesarias para evolucionar.',
    principles: {
      title: {
        line1: 'Operamos sobre una',
        line2: 'base de tres',
        line3: 'principios cruciales...'
      },
      list: [
        {
          title: 'Tradición encuentra innovación',
          description: 'Siete generaciones de experiencia legal combinada con metodologías modernas. Mezclamos principios probados con enfoques innovadores para crear soluciones que funcionan en el panorama empresarial actual.'
        },
        {
          title: 'Estrategia proactiva',
          description: 'Anticipamos y prevenimos desafíos legales antes de que surjan. Nuestro enfoque ahorra tiempo, recursos y reputación a los clientes al abordar los problemas potenciales en su origen.'
        },
        {
          title: 'Mentalidad empresarial',
          description: 'Entendemos que las soluciones legales deben servir a los objetivos comerciales. Cada estrategia que desarrollamos considera el contexto comercial más amplio y los factores específicos de la industria que impactan a nuestros clientes.'
        }
      ]
    }
  },
  services: {
    title: 'Nuestros Servicios',
    areasOfExpertise: 'Áreas de Práctica',
    disputeResolution: {
      title: 'Resolución de Disputas',
      description: 'Combinamos generaciones de experiencia legal con metodologías modernas para resolver disputas comerciales complejas de manera efectiva y eficiente.',
      industries: ['Infraestructura', 'Seguros', 'Construcción', 'Puertos', 'Aeropuertos', 'TI', 'Deportes', 'Derechos de TV']
    },
    corporate: {
      title: 'Asesoría Corporativa',
      description: 'Como servicio exclusivo para nuestros clientes de larga data, brindamos asesoría legal estratégica en asuntos comerciales y corporativos, asegurando el cumplimiento y minimizando riesgos.',
      specialties: ['Negociación de Contratos', 'Gobierno Corporativo', 'Cumplimiento Regulatorio', 'Gestión de Riesgos', 'Planificación Estratégica', 'Desarrollo de Negocios']
    },
    sportsAndEntertainment: {
      title: 'Deportes y Entretenimiento',
      description: 'Nuestra práctica de Deportes y Entretenimiento combina un profundo conocimiento legal con experiencia específica en la industria, convirtiéndonos en asesores de confianza para destacadas entidades deportivas, ligas, clubes y organizadores de eventos.',
      preview: 'Aportamos décadas de experiencia especializada en arbitraje deportivo y derecho del entretenimiento para ofrecer soluciones precisas y adaptadas a las necesidades de nuestros clientes. Ya sea manejando disputas complejas ante el Tribunal de Arbitraje Deportivo, negociando contratos clave de patrocinio o asesorando a organizadores de eventos en asuntos contractuales estratégicos, nuestro enfoque asegura resultados alineados con sus objetivos estratégicos.',
      specialties: [
        'Contratos de Patrocinio y Naming',
        'Arbitrajes Ordinarios y de Apelación ante el TAS',
        'Derechos de Transmisión',
        'Contratos Comerciales Relacionados con Eventos',
        'Traspasos y Transferencias de Jugadores',
        'Disputas Disciplinarias y Económicas'
      ]
    }
  },
  testimonials: {
    title: {
      line1: 'Últimos',
      line2: 'Reconocimientos',
      line3: ''
    },
    clientsTitle: 'Lo que dicen nuestros clientes',
    recognitions: [
      {
        source: "Chambers & Partners",
        year: "2023",
        quote: "Práctica sobresaliente en resolución de disputas con experiencia excepcional en litigios comerciales.",
        rating: "Banda 1"
      },
      {
        source: "Legal 500",
        year: "2023",
        quote: "Firma de primer nivel en asesoría comercial y resolución de disputas.",
        rating: "Nivel 1"
      },
      {
        source: "Best Lawyers",
        year: "2023",
        quote: "Firma líder en derecho corporativo y litigios comerciales.",
        rating: "Excelencia"
      }
    ],
    clients: [
      {
        client: "Global Infrastructure Corp",
        position: "Consejero General",
        quote: "Su enfoque proactivo hacia los desafíos legales nos ha ahorrado innumerables horas y recursos."
      },
      {
        client: "Tech Innovations Ltd",
        position: "CEO",
        quote: "Entienden no solo la ley, sino cómo se aplica a nuestros objetivos comerciales."
      }
    ]
  },
  people: {
    title: {
      line1: 'Nuestro',
      line2: 'equipo',
      line3: ''
    },
    viewProfile: 'Ver Perfil'
  }
}; 