import { useLanguage } from '../../context/LanguageContext';
import ServiceCard from '../services/ServiceCard';
import { Link } from 'react-router-dom';

const Services = () => {
  const { translations, language } = useLanguage();

  const services = {
    en: [
      {
        id: 'dispute-resolution',
        title: 'Dispute Resolution',
        shortPhrase: 'Strategic litigation and arbitration with a legacy of excellence',
        description: 'We combine seven generations of legal expertise with a fresh perspective to handle even the most complex commercial and contractual disputes. We focus on disputes where strategic precision is critical, handling cases across a wide range of industries, including infrastructure, insurance, construction, ports, airports, IT, sports, and TV rights, among others.',
        longDescription: 'Our commercial and administrative litigation and arbitration practices prioritize resolving disputes efficiently. We advocate for negotiation and alternative dispute resolution (ADR) methods whenever feasible, aiming to achieve solutions that bypass the expense and complications of protracted legal proceedings. When litigation becomes necessary, we are tireless in seeking results that are specifically crafted to meet our clients\' unique needs.\n\nWe also handle extraordinary appeals and annulment of arbitral awards before Colombia\'s high courts, ensuring that every legal recourse is explored. In addition, we manage public constitutional actions and assist with the recognition of foreign judgments and exequatur proceedings, providing seamless global enforcement of decisions.\n\nOur commitment to service has earned us numerous accolades, with the most notable recognition coming from Chambers & Partners, Legal 500, LeadersLeague, and Best Lawyers. These prestigious institutions have consistently highlighted our expertise in dispute resolution, litigation, and arbitration, underscoring our position as leaders in the field. Ernesto Gamboa and Eduardo Gamboa, who lead our practice, have both been individually recognized by these rankings for their outstanding legal acumen, further solidifying the firm\'s reputation for excellence and strategic leadership in complex disputes.',
        expertise: [
          'Commercial and Administrative Litigation',
          'National and International Arbitration',
          'Alternative Dispute Resolution (ADR)',
          'Settlement Agreement Negotiation',
          'Infrastructure and Construction',
          'Sports Law and Entertainment',
          'Extraordinary Appeals',
          'Constitutional Actions',
          'Recognition of Foreign Judgments',
          'Ports and Maritime',
          'Insurance and Risk Management',
          'International Enforcement',
          'Class Action Defense',
          'Mediation and Conciliation',
          'High Court Litigation'
        ]
      },
      {
        id: 'commercial-corporate-advisory',
        title: 'Commercial and Corporate Advisory',
        shortPhrase: 'Strategic prevention and risk management for longstanding clients',
        description: 'As an exclusive service for our longstanding clients, our Commercial and Corporate Advisory practice combines our expertise in dispute resolution with proactive strategies to prevent future conflicts. By leveraging this knowledge in dispute resolution, we help clients minimize risks.',
        longDescription: 'Our Commercial and Corporate Advisory practice is built on a foundation of deep understanding of business operations and legal frameworks. We work closely with clients to develop strategies that not only address immediate needs but also anticipate and prevent future challenges.\n\nWe provide comprehensive corporate law advisory, focusing on mergers and acquisitions (M&A) and the structuring of complex transactions. In addition, we offer tailored guidance on civil and commercial contracts, conflict of interest management, and general corporate affairs.',
        expertise: [
          'Corporate Law Advisory',
          'Mergers and Acquisitions (M&A)',
          'Complex Transaction Structuring',
          'Commercial Contract Negotiation',
          'Sports Industry Contracts',
          'Corporate Governance',
          'Risk Prevention',
          'Regulatory Compliance',
          'Due Diligence',
          'Shareholders Agreements',
          'Corporate Restructuring',
          'Joint Venture Structuring',
          'Business Strategy Advisory',
          'Legal Risk Management',
          'Conflict of Interest Management'
        ]
      },
      {
        id: 'sports-and-entertainment',
        title: 'Sports and Entertainment',
        shortPhrase: 'Expert navigation of sports law and entertainment industry dynamics',
        description: 'Our Sports and Entertainment practice combines deep legal knowledge with industry-specific insights, positioning us as trusted advisors to prominent sports entities, leagues, clubs, and event organizers.',
        longDescription: 'Our Sports and Entertainment practice combines deep legal knowledge with industry-specific insights, positioning us as trusted advisors to prominent sports entities, leagues, clubs, and event organizers. Led by Ernesto Gamboa, who brings extensive experience as an arbitrator before the Court of Arbitration for Sport (CAS), and Paula Isaza, whose expertise in both art and law uniquely positions her to manage sophisticated entertainment and sports matters, our team is uniquely equipped to handle complex matters within these dynamic sectors.\n\nWe provide comprehensive legal services including sponsorship and naming rights agreements, player transfers and contractual negotiations, disciplinary representation, economic disputes, and both ordinary and appeal arbitrations before CAS.\n\nIn the entertainment sphere, we manage legal aspects of commercial contracts for events, advertising agreements, and sponsorship deals, ensuring our clients\' interests are effectively protected and promoted.',
        expertise: [
          'Sponsorship and Naming Rights Agreements',
          'Ordinary and Appeal Arbitrations before CAS',
          'Media and Broadcasting Rights',
          'Event-related Commercial Contracts',
          'Player Transfers',
          'Disciplinary and Economic Disputes'
        ]
      }
    ],
    es: [
      {
        id: 'dispute-resolution',
        title: 'Resolución de Conflictos',
        shortPhrase: 'Litigio y arbitraje estratégico con un legado de excelencia',
        description: 'Combinamos siete generaciones de experiencia jurídica con una visión innovadora y estratégica para gestionar controversias comerciales y contractuales complejas. Nuestra práctica abarca, entre otros, conflictos de infraestructura, seguros, construcción, puertos, aeropuertos, tecnología, deportes y entretenimiento.',
        longDescription: 'Priorizamos la resolución eficaz de disputas en nuestras prácticas arbitraje y litigio comercial y administrativo. Promovemos la negociación y el uso de métodos alternativos de resolución de conflictos (ADR) siempre que es posible. No obstante, cuando el litigio es inevitable, invertimos todos nuestros esfuerzos para obtener resultados que se ajusten a las necesidades específicas de nuestros clientes.\n\nPara garantizar que en cada caso se agoten todas las vías legales disponibles, nuestra práctica incluye una comprensiva especialización en la interposición de acciones de tutela y recursos extraordinarios ante las altas cortes de Colombia (casación, anulación de laudos arbitrales y revisión). Bajo la misma perspectiva, gestionamos otro tipo de acciones constitucionales y asistimos en el reconocimiento de sentencias extranjeras y procedimientos de exequátur.\n\nNuestro compromiso con el servicio nos ha valido numerosos reconocimientos por los rankings de abogados más destacados, incluyendo Chambers & Partners, Legal 500, LeadersLeague y Best Lawyers. Estas prestigiosas calificadoras han resaltado de manera consistente nuestra experiencia en resolución de conflictos, litigios y arbitraje, destacando especialmente a Ernesto Gamboa y a Eduardo Gamboa —lideres de nuestra práctica—, como referentes en el sector.',
        expertise: [
          'Litigio Comercial y Administrativo',
          'Arbitraje Nacional e Internacional',
          'Métodos Alternativos de Resolución de Conflictos (ADR)',
          'Negociación de Acuerdos de Transacción',
          'Infraestructura y Construcción',
          'Derecho Deportivo y Entretenimiento',
          'Recursos Extraordinarios',
          'Acciones Constitucionales',
          'Reconocimiento de Sentencias Extranjeras',
          'Puertos y Marítimo',
          'Seguros y Gestión de Riesgos',
          'Ejecución Internacional',
          'Defensa en Acciones de Grupo',
          'Mediación y Conciliación',
          'Litigio ante Altas Cortes'
        ]
      },
      {
        id: 'commercial-corporate-advisory',
        title: 'Derecho Comercial y Corporativo',
        shortPhrase: 'Prevención estratégica y gestión de riesgos para clientes recurrentes',
        description: 'Aprovechando nuestra experiencia en litigios y como un complemento para nuestros clientes recurrentes, prestamos servicios de derecho comercial y corporativo con el principal propósito de prevenir controversias y mitigar riesgos jurídicos.',
        longDescription: 'Nuestra práctica de Derecho Comercial y Corporativo se construye sobre una base de profundo entendimiento de las operaciones comerciales y marcos legales. Trabajamos estrechamente con los clientes para desarrollar estrategias que no solo abordan las necesidades inmediatas sino que también anticipan y previenen desafíos futuros.\n\nEn concreto, ofrecemos servicios integrales en derecho corporativo, con un enfoque especial en fusiones y adquisiciones (M&A) y estructuración de transacciones complejas. Además, asesoramos a nuestros clientes recurrentes en la negociación y celebración de contratos civiles y comerciales, gestión de conflictos de interés, y asuntos corporativos generales.',
        expertise: [
          'Asesoría en Derecho Corporativo',
          'Fusiones y Adquisiciones (M&A)',
          'Estructuración de Transacciones Complejas',
          'Negociación de Contratos Comerciales',
          'Contratos de la Industria Deportiva',
          'Gobierno Corporativo',
          'Prevención de Riesgos',
          'Cumplimiento Regulatorio',
          'Due Diligence',
          'Acuerdos de Accionistas',
          'Reestructuración Corporativa',
          'Estructuración de Joint Ventures',
          'Asesoría en Estrategia Empresarial',
          'Gestión de Riesgos Legales',
          'Gestión de Conflictos de Interés'
        ]
      },
      {
        id: 'sports-and-entertainment',
        title: 'Deportes y Entretenimiento',
        shortPhrase: 'Expertos en derecho deportivo y en la industria del entretenimiento',
        description: 'Nuestra práctica de Deportes y Entretenimiento combina un profundo conocimiento legal con experiencia específica en la industria, convirtiéndonos en asesores de confianza para destacadas entidades deportivas, ligas, clubes y organizadores de eventos.',
        longDescription: 'Nuestra práctica de Deportes y Entretenimiento combina un profundo conocimiento legal con experiencia específica en la industria, convirtiéndonos en asesores de confianza para destacadas entidades deportivas, ligas, clubes y organizadores de eventos. Liderado por Ernesto Gamboa, con amplia trayectoria como árbitro del Tribunal de Arbitraje Deportivo (TAS), y Paula Isaza, cuya experiencia en arte y derecho la posiciona de manera única para gestionar sofisticados asuntos relacionados con el deporte y el entretenimiento, nuestro equipo está preparado para enfrentar los desafíos más complejos de estos dinámicos sectores.\n\nOfrecemos asesoría integral en contratos de patrocinio y naming, convenios de traspasos y transferencias, representación en procesos disciplinarios y disputas económicas, así como en arbitrajes ordinarios y de apelación ante el TAS.\n\nEn entretenimiento, gestionamos aspectos legales relacionados con contratos comerciales para eventos, acuerdos de publicidad y patrocinio, asegurando que los intereses de nuestros clientes sean protegidos y promovidos eficazmente.',
        expertise: [
          'Contratos de Patrocinio y Naming',
          'Arbitrajes Ordinarios y de Apelación ante el TAS',
          'Derechos de Transmisión',
          'Contratos Comerciales Relacionados con Eventos',
          'Traspasos y Transferencias de Jugadores',
          'Disputas Disciplinarias y Económicas'
        ]
      }
    ]
  };

  return (
    <section id="services" className="px-8 py-20 relative">
      <div className="container max-w-7xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-light leading-snug tracking-[-0.01em] mb-20">
          {translations.services.title}
        </h2>

        <div className="grid md:grid-cols-2 gap-16">
          {services[language].map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 border-t border-white/10" />
    </section>
  );
};

export default Services; 